'use client';
import Button from '@/components/ui/Button';
import {
  CONTEST_PAGE,
  REFERRALS_PAGE,
  REFERRAL_LIST_PAGE,
} from '@/constants/routes.const';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

export default function RouteLink({ title, href, MenuIcon }) {
  const pathName = usePathname();

  const isActive = useMemo(() => {
    if (pathName == href) {
      return true;
    }
    if (pathName === REFERRAL_LIST_PAGE && href === REFERRALS_PAGE) {
      return true;
    }
    if (href === CONTEST_PAGE && pathName.includes(CONTEST_PAGE)) {
      return true;
    }
    return false;
  }, [pathName, href]);

  return (
    <Link href={href} className={'contents'}>
      <Button
        variant={isActive ? 'solid' : 'soft'}
        size={'2'}
        color="violet"
        className="flex flex-col items-center gap-1 p-1 , h-[50px] rounded-none "
      >
        <>
          <MenuIcon className="text-xl" />
          <div className="text-xs">{title}</div>
        </>
      </Button>
    </Link>
  );
}
